@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.Blog {
  @include max-container-width;

  > .small-header {
    text-align: center;
    max-width: 35rem;
    margin: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;

    @include wider($tablet-large) {
      flex-direction: row;
      justify-content: center;
      margin-top: 4rem;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15rem;
    margin: 1rem 0;
    -webkit-box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
    -moz-box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
    box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
    border-radius: 10px;

    @include wider($mobile) {
      width: 30%;
    }

    @include wider($tablet-large) {
      margin: 0 1rem;
      width: 25%;
    }

    &:hover {
      -webkit-box-shadow: rgb(135 129 117 / 70%) 0px 8px 24px;
      -moz-box-shadow: rgb(135 129 117 / 70%) 0px 8px 24px;
      box-shadow: rgb(135 129 117 / 70%) 0px 8px 24px;
    }

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .small-header {
      padding: 1rem;
      min-height: 9rem;
    }
  }
}

@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.Tech {
  @include max-container-width;

  &__content {
    display: none;

    @include wider($tablet) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 3rem;
    }
  }

  &__questions {
    width: 40%;
    padding-left: 8rem;

    .Collapsible__contentInner {
      max-width: 32rem;
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    width: 50%;
    margin-top: 3rem;
  }

  .background {
    background: url("../assets/phone.png") no-repeat;
    position: relative;
    height: 20rem;
    background-size: 10rem;
    background-position: center;
    width: 100%;

    @include wider($tablet) {
      height: 29rem;
      background-size: 14rem;
      width: 14.7rem;
    }

    @include wider($desktop-large) {
      background-size: auto;
      width: 17.7rem;
      height: 35rem;
    }
  }

  .screenshot {
    position: absolute;
    width: 8.25rem;
    top: 9px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    right: 0;

    @include wider($tablet) {
      width: 11.5rem;
      top: 20px;
    }

    @include wider($desktop-large) {
      width: auto;
      top: 14px;
      bottom: 0;
      left: 25px;
      right: 0;
      transform: none;
    }
  }
}

.swiper {
  margin-top: 1rem;

  @include wider($tablet) {
    display: none;
  }
}

.swiper-text {
  -webkit-box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
  -moz-box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
  box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
  border-radius: 10px;
  margin: 1rem 1rem 3.5rem 1rem;
  padding: 0.5rem;

  @include wider($mobile) {
    max-width: 27rem;
    margin: 1rem auto 3.5rem auto;
  }

  .small-header {
    margin-bottom: 1rem;
  }
}

@import "./styles/mixins.scss";
@import "./styles/variables.scss";
@import "./styles/typography.scss";

.App {
  #logo {
    width: 10rem;
    height: auto;
  }
}

.section {
  padding: 3rem 2rem 0 2rem;

  @include wider($tablet) {
    padding: 7rem 2rem 0 2rem;
  }

  @include wider($desktop) {
    padding: 7rem 4rem 0 4rem;
  }

  &.grey {
    background-color: $grey;
  }

  &.green {
    background-color: $green;
  }

  &.yellow {
    background-color: $yellow;
  }

  &.more-whitespace {
    padding-bottom: 3rem;

    @include wider($tablet) {
      padding-bottom: 7rem;
    }
  }
}

.join-button {
  background-color: $green;
  border-color: $green;
  -webkit-box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
  -moz-box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
  box-shadow: rgb(135 129 117 / 45%) 0px 8px 24px;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  border-radius: 2rem;
  border-style: solid;
  width: 100%;
  font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif;
  margin: 2rem auto;
  cursor: pointer;

  @include wider($mobile) {
    max-width: 20rem;
  }

  &.yellow {
    background-color: $yellow;
    border-color: $yellow;
  }

  &:active,
  &:hover {
    -webkit-box-shadow: rgb(135 129 117 / 70%) 0px 8px 24px;
    -moz-box-shadow: rgb(135 129 117 / 70%) 0px 8px 24px;
    box-shadow: rgb(135 129 117 / 70%) 0px 8px 24px;
  }
}

input {
  border: 1px solid $black;
  border-radius: 10px;
  padding: 0.75rem 1rem;
  width: 18rem;

  &:focus {
    border-color: $yellow;
    outline-style: solid;
    outline-color: $yellow;
  }

  &.input--hidden {
    display: none;
  }
}

.CollapseHeader {
  position: relative;
  padding-left: 1rem;
  font-size: 1.25rem;
  font-family: Monstera-Regular, Arial, Helvetica, sans-serif;
  width: fit-content;
  cursor: pointer;

  &:before {
    content: url("./assets/chevron.svg");
    position: absolute;
    top: 0rem;
    left: -1.25rem;
    transition: ease-in 0.5s;
  }
}

.Collapsible {
  margin: 2rem 0 0 1.5rem;
}

.Collapsible__trigger {
  &.is-open {
    .CollapseHeader {
      &:before {
        transform: rotate(90deg);
        transition: ease-in 0.5s;
      }
    }
  }
}

.Collapsible__contentInner {
  padding: 0.5rem 1rem;
  max-width: 40rem;
}

@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.Faq {
  position: relative;
  @include max-container-width;

  .main-header {
    text-align: left;
    margin-bottom: 2rem;
  }
}

.bubble {
  display: none;

  @include wider($desktop) {
    display: block;
    position: absolute;
    right: 0;
    bottom: -6rem;
  }
}

.rocket {
  display: none;

  @include wider($desktop) {
    display: block;
    position: absolute;
    top: -2rem;
    right: 17rem;
  }
}

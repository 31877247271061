@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.Team {
  display: flex;
  flex-direction: column;
  @include max-container-width;

  .subheader {
    @include wider($desktop) {
      margin: 1rem 0 0 0;
    }
  }

  @include wider($mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  @include wider($tablet) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include wider($desktop) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include wider($desktop) {
    .main-header,
    .subheader {
      text-align: left;
    }
  }

  &__text {
    grid-column: span 2;

    @include wider($tablet) {
      grid-column: span 3;
      margin-bottom: 2rem;
    }

    @include wider($desktop) {
      grid-column: 4 / span 3;
    }
  }
}

.TeamMember {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  @include wider($mobile) {
    text-align: center;
    margin-top: 0;
  }

  img {
    height: 10rem;
    margin: 0.5rem 0;
  }

  .header-bold {
    @include wider($mobile) {
      max-width: 10rem;
    }
  }

  &.kuba {
    @include wider($desktop) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &.kasia {
    @include wider($desktop) {
      grid-column: 2;
      grid-row: 1;
    }
  }

  &.wiki {
    @include wider($desktop) {
      grid-column: 3;
      grid-row: 1;
    }
  }
}

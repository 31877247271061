@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.Arrow {
  position: absolute;

  &.bottom-left {
    bottom: -4rem;
    left: -8rem;
  }

  &.bottom-right {
    bottom: -4rem;
    right: -7rem;
  }

  &.middle-left {
    left: -10rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &.middle-right {
    right: -6rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &.top-left {
    top: -3rem;
    left: -10rem;
  }

  &.top-right {
    top: -6rem;
    right: -7rem;
  }
}

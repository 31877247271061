@mixin wider($width) {
  @media (min-width: #{$width + 1} ) {
    @content;
  }
}

@mixin max-container-width {
  max-width: 82.875rem;
  margin: 0 auto;
}

@import "./variables.scss";

@font-face {
  font-family: OpenSans-SemiBold;
  src: url(../assets/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(../assets//OpenSans-Regular.ttf);
}

@font-face {
  font-family: Monstera-Bold;
  src: url(../assets//Montserrat-Bold.ttf);
}

@font-face {
  font-family: Monstera-Regular;
  src: url(../assets//Montserrat-Regular.ttf);
}

p,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

a {
  color: $black;
}

.main-header {
  font-family: Monstera-Regular, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  text-align: center;

  @include wider($mobile) {
    font-size: 2.5rem;
  }
}

.small-header {
  font-size: 1.25rem;
  font-family: Monstera-Regular, Arial, Helvetica, sans-serif;

  @include wider($mobile) {
    font-size: 1.5rem;
  }
}

.subheader {
  max-width: 35rem;
  margin: 1rem auto 0 auto;

  @include wider($mobile) {
    text-align: center;
  }
}

.header-bold {
  font-family: Monstera-Bold, Arial, Helvetica, sans-serif;
}

.text-bold {
  font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif;
}

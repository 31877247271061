@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.Header {
  padding: 1rem 2rem 0 2rem;
  -webkit-box-shadow: -2px 5px 35px 1px rgb(135 129 117 / 45%);
  -moz-box-shadow: -2px 5px 35px 1px rgb(135 129 117 / 45%);
  box-shadow: -2px 5px 35px 1px rgb(135 129 117 / 45%);

  .main-header {
    max-width: 13rem;
    margin: 0 auto;
    height: 6rem;

    @include wider($tablet) {
      max-width: none;
      height: auto;
      margin: auto;
    }
  }

  @include wider($desktop) {
    padding: 1rem 4rem 0 4rem;
  }

  &__content {
    margin-top: 2rem;

    @include wider($mobile) {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__image {
    width: 100%;
    margin-top: 2rem;
    max-width: 45rem;

    @include wider($desktop) {
      max-width: 52rem;
    }
  }
}

@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.JoinForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include max-container-width;
  margin: 0 auto;
  position: relative;

  &__form {
    margin-top: 2rem;
  }

  &__element {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  .main-header {
    max-width: 30rem;
  }
}

.hands-image {
  position: absolute;
  display: none;

  @include wider($tablet) {
    display: block;
  }

  &.letter {
    top: -7rem;
    right: 0;
    width: 15rem;

    @include wider($desktop-large) {
      right: 2rem;
      width: 18rem;
    }
  }

  &.pot {
    bottom: -7rem;
    left: 0;
    width: 20rem;

    @include wider($desktop-large) {
      left: 2rem;
    }
  }
}

.sib-form {
  font-family: "Montserrat", sans-serif;

  @include wider($mobile) {
    width: 20rem;
  }

  .entry__error {
    font-size: 0.75rem;
    margin: 0 0 0.875rem;
    font-weight: 500;
  }

  .entry__field {
    border-radius: 0.625rem;
  }

  .entry__label {
    margin: 0.625rem 0 0;
  }
}

#sib-container {
  background: transparent;
  padding: 0;
}

.sib-form-block {
  padding: 0;
}

.sib-form-block__button {
  width: 100%;
  color: $black;
  z-index: 1;
  display: block;
  text-transform: uppercase;
}

.sib-form-message-panel {
  display: none;
}

.privacy-policy {
  font-size: 0.875rem;
}

@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.NavBar {
  @include max-container-width;
  margin: 0 auto;

  @include wider($tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.menu-item {
  text-decoration: none;
  padding: 1rem;

  &:hover {
    background-color: rgba(135, 129, 117, 0.2);
  }
}

.nav-mobile {
  @include wider($tablet) {
    display: none;
  }
}

.nav-desktop {
  display: none;

  @include wider($tablet) {
    display: block;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 32px;
  height: 25px;
  right: 1rem;
  top: 1rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $black;
  border-radius: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $yellow;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 32px;
  width: 32px;
  right: 1rem !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $black;
  height: 32px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  width: 80% !important;
  height: fit-content;
  top: 0;
  -webkit-box-shadow: -9px 7px 22px -12px rgba(135, 129, 117, 0.81);
  -moz-box-shadow: -9px 7px 22px -12px rgba(135, 129, 117, 0.81);
  box-shadow: -9px 7px 22px -12px rgba(135, 129, 117, 0.81);
}

/* General sidebar styles */
.bm-menu {
  background: $white;
  padding: 2.5em 0;
  font-size: 1.15em;
  width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  max-width: fit-content;
}

@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.About {
  @include max-container-width;
  margin: 0 auto;

  .main-header {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @include wider($tablet) {
      flex-direction: row;
      justify-content: space-around;
      margin-top: 4rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;

    @include wider($tablet) {
      max-width: 21rem;
    }

    svg {
      margin-bottom: 1rem;
      height: 3rem;
    }
  }

  &__second-header {
    margin-top: 2rem;

    @include wider($tablet) {
      margin-top: 6rem;
    }
  }

  &__benefits {
    margin-top: 2rem;

    @include wider($tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 4rem;
    }
  }

  &__benefits-text {
    max-width: 32rem;
    justify-self: center;

    p {
      margin-bottom: 1rem;
    }

    &.text-2 {
      @include wider($tablet) {
        order: 1;
      }
    }
  }

  &__benefits-content {
    position: relative;
    padding-left: 1rem;

    &:before {
      content: url("../assets/check.svg");
      position: absolute;
      top: 0.2rem;
      left: -0.75rem;
    }
  }

  &__image {
    margin: 2rem auto 0 auto;
    max-height: 11rem;
    display: block;
    justify-self: center;
    align-self: self-end;

    @include wider($tablet) {
      justify-self: center;
      align-self: self-end;
      margin: 0 0 0.5rem 0;
      max-height: 13rem;
    }

    &.image-2 {
      @include wider($tablet) {
        order: 0;
      }
    }
  }
}

@import "../styles/mixins.scss";
@import "../styles/variables.scss";

.footer-container {
  padding: 2rem;
}

.Footer {
  @include max-container-width;

  @include wider($mobile) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .join-button {
    margin-top: 0;

    @include wider($mobile) {
      margin: 0;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include wider($mobile) {
      align-items: flex-start;
    }

    .menu-item {
      padding: 0.5rem;
    }
  }

  &__social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1rem 0;

    .fb {
      @include wider($mobile) {
        margin-right: 1rem;
      }
    }
  }
}

.sektor-info {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  border-top: 1px solid $shadow-color;
  align-items: center;
  justify-content: space-between;

  @include wider($tablet) {
    flex-direction: row;
  }

  .info-text {
    font-size: 0.875rem;

    @include wider($tablet) {
      width: 60%;
    }
  }
}

.logos {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  @include wider($tablet) {
    margin-top: 0;
  }
}

.sektor-logos {
  height: 3rem;
  margin-left: 1rem;
}

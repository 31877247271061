$black: #1f1f1f;
$yellow: #ffc12e;
$green: #3ad29f;
$white: #ffffff;
$grey: #f7f7f5;

$shadow-color: rgba(135, 129, 117, 0.2);

$mobile: 480px;
$tablet: 768px;
$tablet-large: 999px;
$desktop: 1200px;
$desktop-large: 1440px;
$wide-screen: 1920px;
